<template>
  <Content class="main-support" v-if="$locale">
    <Header :options="{ remove: ['user'] }">
      <template v-slot:user>
        <a class="avatar close-btn" @click="goHome">
          <figure><iconic name="times" /></figure>
        </a>
      </template>
    </Header>
    <div class="main-support-content">
      <Sticky class="main-support-title">
        <div class="main" upper>{{ $locale["faq_label"] }}</div>
      </Sticky>
      <div class="main-support-list">
        <div class="main">
          <Collapse ref="collapse" v-for="(faq, findex) in $locale['faqs']" :key="findex" :title="faq.question">
            <div class="collapse-container semantic" v-html="faq.answer"></div>
          </Collapse>
        </div>
        <Spacer num="1" />
      </div>
    </div>
  </Content>
</template>
<script>
import Header from "../home/Header.vue";
import Sticky from "../widgets/Sticky.vue";
import Content from "../widgets/Content.vue";
import Collapse from "../widgets/Collapse.vue";
export default {
  metaInfo: {
    title: "FAQ - My Fans 1A",
  },
  components: { Header, Sticky, Content, Collapse },
};
</script>

<style lang="scss">
.main-support {
  &-content {
    margin: $header_height 0 0 0;
  }
  &-title,
  &-titleSection {
    font-size: 2rem;
  }
  &-title {
    padding: $mpadding;
    background-color: #fff;
    z-index: 1;
  }
  &-titleSection {
    padding: $mpadding $mpadding 0 $mpadding;
    font-size: 1.5rem;
  }
  &-list {
    padding: $mpadding;
    > .main {
      background-color: #fff;
      border: solid 1px $alto;
      border-radius: $mpadding/4;
      overflow: hidden;
    }
    .collapse {
      &:not(:last-child) {
        border-bottom: solid 1px $alto;
      }
      &.open {
        .collapse-header {
          border-bottom: solid 1px $alto;
        }
      }
      &-header {
        padding: $mpadding $mpadding;
        font-weight: bold;
      }
      &-container {
        padding: $mpadding $mpadding;
      }
    }
  }
}
</style>
